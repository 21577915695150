import React from 'react';
import './LandingElement.scss';

export default ({
  appearAt,
  scrollPosition,
  children,
}) => (
  <div className={`hidden-by-default ${scrollPosition > appearAt ? 'show' : 'hidden'}`}>
    {children}
  </div>
);
