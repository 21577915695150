import React, { useEffect, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from "react-helmet"
import Hero from 'src/components/hero/Hero';
import Container from 'src/components/UI/Container';
import Header from 'src/components/header/Header';
import LandingElement from 'src/components/landing-element/LandingElement';
import Footer from 'src/components/footer/Footer';
import useScroll from 'src/hooks/useScroll';
import './home.scss';
import 'src/styles/reset.scss';
import 'src/styles/general.scss';

const query = graphql`
  query {
    visionTextsData: allVisionTextsJson {
      nodes {
        id
        title
        text
        order
      }
    }
    freeLessons: allWebsiteTextsJson(filter: {identifier: {eq: "free-lessons"}}) {
      nodes {
        title
        text
        id
        shown
      }
    }
  }
`;

export default () => {
  const scrollPosition = useScroll();

  const {
    visionTextsData: { nodes },
    freeLessons: { nodes: [{shown: showFreeLessons}] },
  } = useStaticQuery(query);

  const visionTexts = useMemo(() => {
    return nodes.map((node) => ({ ...node }));
  }, [nodes]);

  return (
    <div className="page home">
      <Header />
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#23356C" />
        <meta
          name="description"
          content="BJJ Gent stands as the pioneer of Brazilian Jiu-Jitsu in Ghent (est. 2008), earning the distinction of being one of Belgium's earliest academies."
        />
        <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;900&display=swap" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet" />
        <title>BJJ Gent</title>
      </Helmet>

      <Hero showFreeLessons={showFreeLessons} />
      <Container className="values-container dark">
        <LandingElement appearAt={120} scrollPosition={scrollPosition}>
          <h2 className="eye-catcher red">Our vision</h2>
        </LandingElement>

        <section className="values-grid">
          {
            visionTexts.sort((a, b) => a.order - b.order).map(({
              id,
              title,
              text
            }) => (
              <div key={id} className="value-group">
                <LandingElement appearAt={120} scrollPosition={scrollPosition}>
                  <h3 className="eye-catcher dark">{title}</h3>
                  <p className="sub-text dark">
                    {text}
                  </p>
                </LandingElement>
              </div>
            ))
          }
        </section>
      </Container>
      <Footer />
    </div>
  );
};
