import React, { useCallback, useState, useEffect } from 'react';
import useScroll from 'src/hooks/useScroll';
import Container from 'src/components/UI/Container';
import ButtonLink from 'src/components/button/ButtonLink';
import './Hero.scss';

export default ({
  showFreeLessons,
}) => {
  const scrollPosition = useScroll();
  const [videoIsHovered, setVideoIsHovered] = useState(false);
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    // Advanced Safari detection using duck-typing given to us by this cool dude: https://stackoverflow.com/a/9851769
    setIsSafari(/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)) || navigator.vendor.indexOf('Apple Computer') > -1);
  }, []);

  return (
    <div className="hero">
      <Container>
        <svg className={`safari-only-text ${isSafari ? 'shown' : ''}`} viewBox="0 0 800 450">
          <path d="M90.9,130.7c0,5.8-1.6,10.6-4.7,14.2c-3.1,3.7-7.4,6.3-12.7,8.1c-5.3,1.7-11.1,2.6-17.5,2.6H8.2V58.4h56.4
        		c4.6,0,8.5,1.2,11.8,3.6c3.3,2.4,5.9,5.5,7.7,9.4c1.8,3.8,2.7,7.8,2.7,11.9c0,4.5-1.2,8.8-3.5,13c-2.3,4.2-5.8,7.3-10.5,9.4
        		c5.6,1.6,10,4.5,13.2,8.6C89.3,118.5,90.9,123.9,90.9,130.7z M34.9,81.1V96h18.2c1.2,0,2.3-0.2,3.4-0.6c1.1-0.4,2-1.2,2.7-2.3
        		c0.7-1.1,1.1-2.6,1.1-4.7c0-1.8-0.3-3.3-0.9-4.3c-0.6-1-1.4-1.8-2.3-2.3c-1-0.5-2-0.8-3.2-0.8H34.9z M63.8,125.2
        		c0-1.6-0.3-3.1-0.9-4.3c-0.6-1.2-1.4-2.2-2.4-2.9c-1-0.7-2.2-1.1-3.6-1.1h-22v16.3H56c1.5,0,2.8-0.3,4-1c1.2-0.6,2.1-1.6,2.8-2.7
        		C63.4,128.2,63.8,126.8,63.8,125.2z"/>
          <path d="M102.7,155.6V58.4h44.6c4.7,0,9.1,1,13.1,2.9c4,1.9,7.5,4.5,10.4,7.7c2.9,3.2,5.2,6.9,6.8,10.9c1.6,4,2.4,8.1,2.4,12.2
        		c0,3.7-0.5,7.3-1.6,10.8c-1.1,3.5-2.7,6.6-4.8,9.5c-2.1,2.9-4.6,5.4-7.5,7.5l20.5,35.7h-29.3l-17.1-29.8h-10.8v29.8H102.7z
        		 M129.3,102.5h16.8c1.1,0,2.1-0.4,3.1-1.2c1-0.8,1.8-2,2.5-3.6c0.7-1.6,1-3.4,1-5.6c0-2.3-0.4-4.2-1.2-5.7
        		c-0.8-1.5-1.7-2.7-2.9-3.5c-1.1-0.8-2.3-1.2-3.4-1.2h-16.1V102.5z"/>
          <path d="M217.5,58.4h29.8l30.9,97.2h-27.1l-5-19h-27.5l-5,19h-27.2L217.5,58.4z M241.4,118.8l-9-34.2l-9,34.2H241.4z" />
          <path d="M281.7,135.7l44.1-54.1h-42V58.4h74.2v19.8l-41.3,54.1h42.1v23.3h-77V135.7z" />
          <path d="M371.4,155.6V58.4h26.7v97.2H371.4z" />
          <path d="M414.8,155.6V58.4h26.7v73.9h44.1v23.3H414.8z" />
          <path d="M494.9,155.6V58.4h26.7v97.2H494.9z" />
          <path d="M560.7,58.4h29.8l30.9,97.2h-27.1l-5-19h-27.5l-5,19h-27.2L560.7,58.4z M584.6,118.8l-9-34.2l-9,34.2H584.6z" />
          <path d="M656.2,107.8v47.8h-26.7V58.4h20.8l39,49.4V58.4H716v97.2h-21.2L656.2,107.8z" />
          <path d="M5.6,244.8c0.5,0.4,2.3,1.1,5.2,2.3c2.9,1.2,6.3,1.8,10.3,1.8c2.8,0,5.1-0.5,6.8-1.4c1.7-0.9,3-2.4,3.9-4.4
        		c0.9-2,1.5-4.6,1.8-7.8c0.3-3.2,0.5-7.1,0.5-11.7v-48.9h26.7v49c0,7.3-0.4,14-1.3,19.9c-0.9,6-2.6,11.1-5.3,15.5
        		c-2.6,4.3-6.6,7.7-11.9,10c-5.3,2.3-12.4,3.5-21.2,3.5c-4.2,0-8-0.5-11.5-1.5c-3.5-1-6.7-2.5-9.6-4.5L5.6,244.8z"/>
          <path d="M76.4,271.9v-97.2h26.7v97.2H76.4z" />
          <path d="M162.2,272.6c-7.8,0-14.4-1.3-20-3.8c-5.6-2.6-10.1-6.1-13.6-10.5c-3.5-4.5-6.1-9.6-7.7-15.4c-1.6-5.8-2.5-11.9-2.5-18.2
        		v-49.9h26.7v49.9c0,3.2,0.3,6.2,1,9.2c0.6,2.9,1.6,5.5,3,7.8c1.4,2.3,3.1,4.1,5.3,5.4c2.1,1.3,4.8,2,7.9,2c3.2,0,5.9-0.7,8.1-2
        		c2.2-1.3,3.9-3.1,5.3-5.5c1.3-2.3,2.3-4.9,2.9-7.9s1-5.9,1-9v-49.9h26.7v49.9c0,6.7-0.9,13-2.7,18.9c-1.8,5.8-4.5,10.9-8,15.3
        		c-3.6,4.4-8.1,7.8-13.6,10.2C176.3,271.4,169.8,272.6,162.2,272.6z"/>
          <path d="M245.9,244.8c0.5,0.4,2.3,1.1,5.2,2.3c2.9,1.2,6.3,1.8,10.3,1.8c2.8,0,5.1-0.5,6.8-1.4c1.7-0.9,3-2.4,3.9-4.4
        		c0.9-2,1.5-4.6,1.8-7.8c0.3-3.2,0.5-7.1,0.5-11.7v-48.9h26.7v49c0,7.3-0.4,14-1.3,19.9c-0.9,6-2.6,11.1-5.3,15.5
        		c-2.6,4.3-6.6,7.7-11.9,10c-5.3,2.3-12.4,3.5-21.2,3.5c-4.2,0-8-0.5-11.5-1.5c-3.5-1-6.7-2.5-9.6-4.5L245.9,244.8z"/>
          <path d="M316.7,271.9v-97.2h26.7v97.2H316.7z" />
          <path d="M435.7,198h-27.9v73.9h-26.7V198h-28.1v-23.3h82.7V198z" />
          <path d="M504.3,206.6c-3.1-1.8-5.9-3.3-8.5-4.4c-2.6-1.1-5.3-2.2-8.3-3.1c-3-0.9-6-1.4-8.9-1.4c-2.3,0-4.2,0.4-5.6,1.1
        		c-1.5,0.7-2.2,1.9-2.2,3.7c0,1.6,0.7,2.9,2.2,3.9c1.5,1,3.5,1.9,6.2,2.7c2.7,0.8,5.9,1.8,9.6,3c6,1.8,11.2,3.9,15.6,6.2
        		c4.4,2.4,7.8,5.4,10.1,9c2.4,3.7,3.6,8.5,3.6,14.6c0,5.8-1,10.6-3.1,14.6c-2.1,3.9-4.9,7.1-8.5,9.4c-3.6,2.4-7.5,4-11.9,5.1
        		c-4.4,1-8.9,1.6-13.4,1.6c-4.7,0-9.5-0.5-14.6-1.4c-5.1-1-10-2.3-14.7-4c-4.7-1.7-9.1-3.7-13-6l11.5-23.4c3.6,2.2,7,4,10,5.3
        		c3,1.4,6.4,2.6,10.1,3.7c3.7,1.1,7.4,1.6,11.2,1.6c2.9,0,4.9-0.4,6.1-1.1c1.1-0.7,1.7-1.7,1.7-2.9c0-1.9-1-3.4-3-4.4
        		c-2-1-4.6-2-7.9-3c-3.2-0.9-6.8-2.1-10.7-3.3c-5.7-1.9-10.3-4.1-14-6.5c-3.6-2.4-6.3-5.3-8.1-8.6c-1.7-3.3-2.6-7.3-2.6-12
        		c0-7.2,1.7-13.1,5.1-17.9c3.4-4.8,7.8-8.4,13.3-10.9c5.5-2.5,11.5-3.7,17.9-3.7c4.7,0,9.3,0.6,13.8,1.7c4.4,1.1,8.6,2.5,12.5,4.2
        		c3.9,1.6,7.3,3.2,10.2,4.7L504.3,206.6z"/>
          <path d="M572.3,272.6c-7.8,0-14.4-1.3-20-3.8c-5.6-2.6-10.1-6.1-13.6-10.5c-3.5-4.5-6.1-9.6-7.7-15.4c-1.6-5.8-2.5-11.9-2.5-18.2
        		v-49.9h26.7v49.9c0,3.2,0.3,6.2,1,9.2c0.6,2.9,1.6,5.5,3,7.8c1.4,2.3,3.1,4.1,5.3,5.4c2.1,1.3,4.8,2,7.9,2c3.2,0,5.9-0.7,8.1-2
        		c2.2-1.3,3.9-3.1,5.3-5.5c1.3-2.3,2.3-4.9,2.9-7.9c0.6-2.9,1-5.9,1-9v-49.9h26.7v49.9c0,6.7-0.9,13-2.7,18.9
        		c-1.8,5.8-4.5,10.9-8,15.3c-3.6,4.4-8.1,7.8-13.6,10.2C586.4,271.4,579.9,272.6,572.3,272.6z"/>
          <path d="M56.3,336.5v19.2h16.9c-2.5,2.6-5.3,4.7-8.2,6.4c-3.9,2.1-8,3.2-12.3,3.2c-3.4,0-6.4-0.6-9.2-1.8c-2.7-1.2-5.1-3-7-5.2
        		c-2-2.2-3.5-4.9-4.6-8c-1.1-3.1-1.6-6.6-1.6-10.4c0-3.7,0.5-7.2,1.6-10.3c1-3.1,2.6-5.9,4.5-8.1c2-2.3,4.3-4.1,6.9-5.3
        		c2.6-1.3,5.6-1.9,8.8-1.9c4.3,0,8.3,1.2,12.2,3.5s6.7,5.4,8.6,9.2l19.8-15.2c-3.5-6.6-8.8-11.7-15.9-15.5
        		c-7.1-3.8-15.1-5.7-23.8-5.7c-7,0-13.6,1.2-19.6,3.7c-6,2.5-11.3,5.9-15.8,10.3c-4.5,4.4-8.1,9.6-10.6,15.4
        		C4.3,325.6,3,332,3,338.8c0,7.9,1.3,15,3.8,21.1c2.5,6.2,6,11.4,10.3,15.7c4.4,4.3,9.4,7.6,15.1,9.9c5.7,2.2,11.7,3.4,18,3.4
        		c9,0,17-3,23.9-8.8v8.2h22v-51.7H56.3z"/>
          <path d="M178.6,364.9v23.3h-69.2V291h68v23.3H136V328h35.3v21.6H136v15.3H178.6z" />
          <path d="M217.7,340.4v47.8h-26.7V291h20.8l39,49.4V291h26.7v97.2h-21.2L217.7,340.4z" />
          <path d="M369.9,314.3H342v73.9h-26.7v-73.9h-28.1V291h82.7V314.3z" />
        </svg>
        <svg className="clipping-path-svg-container" viewBox="0 0 800 450">
          <defs>
            <clipPath clipPathUnits="objectBoundingBox" id="text" transform="scale(0.00125, 0.00222222)">
              <path d="M90.9,130.7c0,5.8-1.6,10.6-4.7,14.2c-3.1,3.7-7.4,6.3-12.7,8.1c-5.3,1.7-11.1,2.6-17.5,2.6H8.2V58.4h56.4
            		c4.6,0,8.5,1.2,11.8,3.6c3.3,2.4,5.9,5.5,7.7,9.4c1.8,3.8,2.7,7.8,2.7,11.9c0,4.5-1.2,8.8-3.5,13c-2.3,4.2-5.8,7.3-10.5,9.4
            		c5.6,1.6,10,4.5,13.2,8.6C89.3,118.5,90.9,123.9,90.9,130.7z M34.9,81.1V96h18.2c1.2,0,2.3-0.2,3.4-0.6c1.1-0.4,2-1.2,2.7-2.3
            		c0.7-1.1,1.1-2.6,1.1-4.7c0-1.8-0.3-3.3-0.9-4.3c-0.6-1-1.4-1.8-2.3-2.3c-1-0.5-2-0.8-3.2-0.8H34.9z M63.8,125.2
            		c0-1.6-0.3-3.1-0.9-4.3c-0.6-1.2-1.4-2.2-2.4-2.9c-1-0.7-2.2-1.1-3.6-1.1h-22v16.3H56c1.5,0,2.8-0.3,4-1c1.2-0.6,2.1-1.6,2.8-2.7
            		C63.4,128.2,63.8,126.8,63.8,125.2z"/>
              <path d="M102.7,155.6V58.4h44.6c4.7,0,9.1,1,13.1,2.9c4,1.9,7.5,4.5,10.4,7.7c2.9,3.2,5.2,6.9,6.8,10.9c1.6,4,2.4,8.1,2.4,12.2
            		c0,3.7-0.5,7.3-1.6,10.8c-1.1,3.5-2.7,6.6-4.8,9.5c-2.1,2.9-4.6,5.4-7.5,7.5l20.5,35.7h-29.3l-17.1-29.8h-10.8v29.8H102.7z
            		 M129.3,102.5h16.8c1.1,0,2.1-0.4,3.1-1.2c1-0.8,1.8-2,2.5-3.6c0.7-1.6,1-3.4,1-5.6c0-2.3-0.4-4.2-1.2-5.7
            		c-0.8-1.5-1.7-2.7-2.9-3.5c-1.1-0.8-2.3-1.2-3.4-1.2h-16.1V102.5z"/>
              <path d="M217.5,58.4h29.8l30.9,97.2h-27.1l-5-19h-27.5l-5,19h-27.2L217.5,58.4z M241.4,118.8l-9-34.2l-9,34.2H241.4z" />
              <path d="M281.7,135.7l44.1-54.1h-42V58.4h74.2v19.8l-41.3,54.1h42.1v23.3h-77V135.7z" />
              <path d="M371.4,155.6V58.4h26.7v97.2H371.4z" />
              <path d="M414.8,155.6V58.4h26.7v73.9h44.1v23.3H414.8z" />
              <path d="M494.9,155.6V58.4h26.7v97.2H494.9z" />
              <path d="M560.7,58.4h29.8l30.9,97.2h-27.1l-5-19h-27.5l-5,19h-27.2L560.7,58.4z M584.6,118.8l-9-34.2l-9,34.2H584.6z" />
              <path d="M656.2,107.8v47.8h-26.7V58.4h20.8l39,49.4V58.4H716v97.2h-21.2L656.2,107.8z" />
              <path d="M5.6,244.8c0.5,0.4,2.3,1.1,5.2,2.3c2.9,1.2,6.3,1.8,10.3,1.8c2.8,0,5.1-0.5,6.8-1.4c1.7-0.9,3-2.4,3.9-4.4
            		c0.9-2,1.5-4.6,1.8-7.8c0.3-3.2,0.5-7.1,0.5-11.7v-48.9h26.7v49c0,7.3-0.4,14-1.3,19.9c-0.9,6-2.6,11.1-5.3,15.5
            		c-2.6,4.3-6.6,7.7-11.9,10c-5.3,2.3-12.4,3.5-21.2,3.5c-4.2,0-8-0.5-11.5-1.5c-3.5-1-6.7-2.5-9.6-4.5L5.6,244.8z"/>
              <path d="M76.4,271.9v-97.2h26.7v97.2H76.4z" />
              <path d="M162.2,272.6c-7.8,0-14.4-1.3-20-3.8c-5.6-2.6-10.1-6.1-13.6-10.5c-3.5-4.5-6.1-9.6-7.7-15.4c-1.6-5.8-2.5-11.9-2.5-18.2
            		v-49.9h26.7v49.9c0,3.2,0.3,6.2,1,9.2c0.6,2.9,1.6,5.5,3,7.8c1.4,2.3,3.1,4.1,5.3,5.4c2.1,1.3,4.8,2,7.9,2c3.2,0,5.9-0.7,8.1-2
            		c2.2-1.3,3.9-3.1,5.3-5.5c1.3-2.3,2.3-4.9,2.9-7.9s1-5.9,1-9v-49.9h26.7v49.9c0,6.7-0.9,13-2.7,18.9c-1.8,5.8-4.5,10.9-8,15.3
            		c-3.6,4.4-8.1,7.8-13.6,10.2C176.3,271.4,169.8,272.6,162.2,272.6z"/>
              <path d="M245.9,244.8c0.5,0.4,2.3,1.1,5.2,2.3c2.9,1.2,6.3,1.8,10.3,1.8c2.8,0,5.1-0.5,6.8-1.4c1.7-0.9,3-2.4,3.9-4.4
            		c0.9-2,1.5-4.6,1.8-7.8c0.3-3.2,0.5-7.1,0.5-11.7v-48.9h26.7v49c0,7.3-0.4,14-1.3,19.9c-0.9,6-2.6,11.1-5.3,15.5
            		c-2.6,4.3-6.6,7.7-11.9,10c-5.3,2.3-12.4,3.5-21.2,3.5c-4.2,0-8-0.5-11.5-1.5c-3.5-1-6.7-2.5-9.6-4.5L245.9,244.8z"/>
              <path d="M316.7,271.9v-97.2h26.7v97.2H316.7z" />
              <path d="M435.7,198h-27.9v73.9h-26.7V198h-28.1v-23.3h82.7V198z" />
              <path d="M504.3,206.6c-3.1-1.8-5.9-3.3-8.5-4.4c-2.6-1.1-5.3-2.2-8.3-3.1c-3-0.9-6-1.4-8.9-1.4c-2.3,0-4.2,0.4-5.6,1.1
            		c-1.5,0.7-2.2,1.9-2.2,3.7c0,1.6,0.7,2.9,2.2,3.9c1.5,1,3.5,1.9,6.2,2.7c2.7,0.8,5.9,1.8,9.6,3c6,1.8,11.2,3.9,15.6,6.2
            		c4.4,2.4,7.8,5.4,10.1,9c2.4,3.7,3.6,8.5,3.6,14.6c0,5.8-1,10.6-3.1,14.6c-2.1,3.9-4.9,7.1-8.5,9.4c-3.6,2.4-7.5,4-11.9,5.1
            		c-4.4,1-8.9,1.6-13.4,1.6c-4.7,0-9.5-0.5-14.6-1.4c-5.1-1-10-2.3-14.7-4c-4.7-1.7-9.1-3.7-13-6l11.5-23.4c3.6,2.2,7,4,10,5.3
            		c3,1.4,6.4,2.6,10.1,3.7c3.7,1.1,7.4,1.6,11.2,1.6c2.9,0,4.9-0.4,6.1-1.1c1.1-0.7,1.7-1.7,1.7-2.9c0-1.9-1-3.4-3-4.4
            		c-2-1-4.6-2-7.9-3c-3.2-0.9-6.8-2.1-10.7-3.3c-5.7-1.9-10.3-4.1-14-6.5c-3.6-2.4-6.3-5.3-8.1-8.6c-1.7-3.3-2.6-7.3-2.6-12
            		c0-7.2,1.7-13.1,5.1-17.9c3.4-4.8,7.8-8.4,13.3-10.9c5.5-2.5,11.5-3.7,17.9-3.7c4.7,0,9.3,0.6,13.8,1.7c4.4,1.1,8.6,2.5,12.5,4.2
            		c3.9,1.6,7.3,3.2,10.2,4.7L504.3,206.6z"/>
              <path d="M572.3,272.6c-7.8,0-14.4-1.3-20-3.8c-5.6-2.6-10.1-6.1-13.6-10.5c-3.5-4.5-6.1-9.6-7.7-15.4c-1.6-5.8-2.5-11.9-2.5-18.2
            		v-49.9h26.7v49.9c0,3.2,0.3,6.2,1,9.2c0.6,2.9,1.6,5.5,3,7.8c1.4,2.3,3.1,4.1,5.3,5.4c2.1,1.3,4.8,2,7.9,2c3.2,0,5.9-0.7,8.1-2
            		c2.2-1.3,3.9-3.1,5.3-5.5c1.3-2.3,2.3-4.9,2.9-7.9c0.6-2.9,1-5.9,1-9v-49.9h26.7v49.9c0,6.7-0.9,13-2.7,18.9
            		c-1.8,5.8-4.5,10.9-8,15.3c-3.6,4.4-8.1,7.8-13.6,10.2C586.4,271.4,579.9,272.6,572.3,272.6z"/>
              <path d="M56.3,336.5v19.2h16.9c-2.5,2.6-5.3,4.7-8.2,6.4c-3.9,2.1-8,3.2-12.3,3.2c-3.4,0-6.4-0.6-9.2-1.8c-2.7-1.2-5.1-3-7-5.2
            		c-2-2.2-3.5-4.9-4.6-8c-1.1-3.1-1.6-6.6-1.6-10.4c0-3.7,0.5-7.2,1.6-10.3c1-3.1,2.6-5.9,4.5-8.1c2-2.3,4.3-4.1,6.9-5.3
            		c2.6-1.3,5.6-1.9,8.8-1.9c4.3,0,8.3,1.2,12.2,3.5s6.7,5.4,8.6,9.2l19.8-15.2c-3.5-6.6-8.8-11.7-15.9-15.5
            		c-7.1-3.8-15.1-5.7-23.8-5.7c-7,0-13.6,1.2-19.6,3.7c-6,2.5-11.3,5.9-15.8,10.3c-4.5,4.4-8.1,9.6-10.6,15.4
            		C4.3,325.6,3,332,3,338.8c0,7.9,1.3,15,3.8,21.1c2.5,6.2,6,11.4,10.3,15.7c4.4,4.3,9.4,7.6,15.1,9.9c5.7,2.2,11.7,3.4,18,3.4
            		c9,0,17-3,23.9-8.8v8.2h22v-51.7H56.3z"/>
              <path d="M178.6,364.9v23.3h-69.2V291h68v23.3H136V328h35.3v21.6H136v15.3H178.6z" />
              <path d="M217.7,340.4v47.8h-26.7V291h20.8l39,49.4V291h26.7v97.2h-21.2L217.7,340.4z" />
              <path d="M369.9,314.3H342v73.9h-26.7v-73.9h-28.1V291h82.7V314.3z" />
              <circle className={`covering-circle ${videoIsHovered ? 'expand' : ''}`} cx="400" cy="225" r="800" />
            </clipPath>
          </defs>
        </svg>
        <div onMouseEnter={() => setVideoIsHovered(true)} onMouseLeave={() => setVideoIsHovered(false)} className={`video-container ${isSafari ? 'safari' : ''} ${videoIsHovered ? 'hovered' : ''}`}>
          <iframe src={`https://www.youtube-nocookie.com/embed/6FJyMef0c4Q?autoplay=1&mute=1&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&vq=hd1080&playlist=6FJyMef0c4Q`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
        {
          showFreeLessons ? (
            <ButtonLink href="./programs#free-lesson" className="call-to-action">Get a free lesson</ButtonLink>
          ) : ''
        }
      </Container>
    </div>
  );
}
